import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { updatePattern } from '../services/entity/pattern/pattern-api';
import { areObjectsEqual } from '../helpers/common/areObjectsEqual';
import { useOrgData } from './OrgDataContext';
import { useGetData } from '../services/api/api-tools';
import { useEngine } from './EngineContext';
import { useSocket } from './SocketContext';

export const PatternSetupContext = createContext();

export const usePatternSetup = () => useContext(PatternSetupContext);

export const PatternSetupProvider = ({ children }) => {
  const { workstationSelected } = useOrgData();
  const { refetchPatternsDetection } = useEngine();
  const { sendEvent } = useSocket();

  const [isPatternPanelOpened, setIsPatternPanelOpened] = useState(false);
  const [isPatternTextOnEdit, setIsPatternTextOnEdit] = useState(false);

  const [copyOriginalPattern, setCopyOriginalPattern] = useState(null);

  const [patternId, setPatternId] = useState(null);
  const [patternName, setPatternName] = useState('NEW PATTERN');
  const [patternEventTypes, setPatternEventTypes] = useState([]);
  const [patternIsActive, setPatternIsActive] = useState(false);
  const [patternLastModified, setPatternLastModified] = useState('');
  const [patternCommentary, setPatternCommentary] = useState('');

  const [logsChecked, setLogsChecked] = useState({});

  const patternsQueryKey = 'patternsList';
  const { data: patterns, refetch: refetchPatterns } = useGetData(patternsQueryKey, 'patterns');

  const loadEditingPattern = (pattern) => {
    setCopyOriginalPattern(pattern);
    setPatternId(pattern.id);
    setPatternName(pattern.name);
    setPatternEventTypes(
      pattern.pattern_event_types.map((patternEventType, index) => ({
        ...patternEventType,
        order: index + 1,
      })),
    );
    setPatternIsActive(pattern.is_active);
    setPatternLastModified(pattern.last_modified);
    setPatternCommentary(pattern.commentary);
  };

  const cleanEditingPattern = () => {
    setCopyOriginalPattern(null);
    setPatternId(null);
    setPatternName('NEW PATTERN');
    setPatternEventTypes([]);
    setPatternIsActive(true);
    setPatternLastModified('');
    setPatternCommentary('');
    setLogsChecked({});
  };

  // automatic pattern update when changes are detected
  useEffect(() => {
    if (!isPatternTextOnEdit && workstationSelected?.id > 0) {
      const updatedPattern = {
        name: patternName,
        is_active: patternIsActive,
        last_modified: patternLastModified,
        pattern_event_types: patternEventTypes,
        commentary: patternCommentary,
        workstation_id: workstationSelected.id,
      };

      if (copyOriginalPattern && !areObjectsEqual(copyOriginalPattern, updatedPattern)) {
        const lastModified = new Date().toISOString();

        const body = {
          name: patternName,
          is_active: patternIsActive,
          last_modified: lastModified,
          pattern_event_types: patternEventTypes,
          commentary: patternCommentary,
          workstation_id: workstationSelected.id,
        };

        const updatePatternOnEdit = async () => {
          const response = await updatePattern(body, patternId);
          if (response) {
            setPatternLastModified(lastModified);
            refetchPatterns();

            if (patternIsActive || copyOriginalPattern.is_active) {
              sendEvent('ihm_update_patterns');
              refetchPatternsDetection();
            }

            setCopyOriginalPattern(response);
          }
        };

        updatePatternOnEdit();
      }
    }
  }, [patternName, patternEventTypes, patternIsActive, isPatternTextOnEdit]);

  useEffect(() => {
    if (patternEventTypes.length < 2) {
      setPatternIsActive(false);
    }
  }, [patternEventTypes]);

  const contextValue = useMemo(
    () => ({
      patternName,
      setPatternName,
      isPatternPanelOpened,
      setIsPatternPanelOpened,
      patternEventTypes,
      setPatternEventTypes,
      logsChecked,
      setLogsChecked,
      setIsPatternTextOnEdit,
      loadEditingPattern,
      patternLastModified,
      cleanEditingPattern,
      patternIsActive,
      setPatternIsActive,
      patternId,
      patternCommentary,
      setPatternCommentary,
      patternsQueryKey,
      patterns,
      refetchPatterns,
    }),
    [
      patternName,
      setPatternName,
      isPatternPanelOpened,
      setIsPatternPanelOpened,
      patternEventTypes,
      setPatternEventTypes,
      logsChecked,
      setLogsChecked,
      setIsPatternTextOnEdit,
      loadEditingPattern,
      patternLastModified,
      cleanEditingPattern,
      patternIsActive,
      setPatternIsActive,
      patternId,
      patternCommentary,
      setPatternCommentary,
      patternsQueryKey,
      patterns,
      refetchPatterns,
    ],
  );

  return <PatternSetupContext.Provider value={contextValue}>{children}</PatternSetupContext.Provider>;
};
